import map from 'lodash/map';
import memoize from 'lodash/memoize';
import groupBy from 'lodash/groupBy';
import omitBy from 'lodash/omitBy';
import isEmpty from 'lodash/isEmpty';
import { subYears, format } from 'date-fns/esm';
import qs from 'qs';

import { api } from 'constants/api';
import { dayInMS } from 'constants/constants';
import { handleRequest } from 'helpers/handleRequest';
import * as parsers from 'helpers/parsers';

import { OperationEnum } from 'helpers/types';
import { Attachment, AttachmentContentType, Investment, SortByTableProps, SortByQuery } from 'helpers/types';
import { FormRenderProps } from 'react-final-form';

export const scrollToTop = () => {
  const c =
    (document.documentElement && document.documentElement.scrollTop) || (document.body && document.body.scrollTop);
  if (c && c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};

export const dateStringToDate = (date: string): Date => {
  const arr: Array<string> = date.split('.');

  return new Date(parseInt(arr[2], 10), parseInt(arr[1], 10) - 1, parseInt(arr[0], 10));
};

const rangeToCategories = (range: any) => [
  null, // don't know why but chart skips 1st category
  ...map(range, (time) => {
    const date = new Date(time);

    return format(date, 'D/M/YYYY');
  }),
];
// TODO: should be moved into normalizer ?
export const datesStringsToRange = (from: string, to: string, items: number): Array<string> => {
  const fromDateTime: number = dateStringToDate(from).getTime();
  const toDateTime: number = dateStringToDate(to).getTime();
  const range: Array<number> = [];
  const step: number = (toDateTime - fromDateTime) / dayInMS / (items - 1);

  let stepTime = fromDateTime;

  while (stepTime <= toDateTime) {
    range.push(stepTime);

    stepTime = stepTime + dayInMS * step;
  }

  return rangeToCategories(range);
};

export const markersValuesFormats = {
  totalDeposit: (obj: Object, val: number) =>
    val !== null
      ? parsers.currencyFormatter(val, undefined, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })
      : 'N/A',
  totalPossession: (obj: Object, val: number) =>
    val !== null
      ? parsers.currencyFormatter(val, undefined, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })
      : 'N/A',
  quarterChangeValue: (obj: any, val: number) =>
    val !== null
      ? `${parsers.currencyChangeFormatter(val, obj.quarterChangePercentage, undefined, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })}`
      : 'N/A',
  yearChangeValue: (obj: any, val: number) =>
    val !== null
      ? `${parsers.currencyChangeFormatter(val, obj.yearChangePercentage, undefined, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })}`
      : 'N/A',
  avgPerformance1Year: (obj: Object, val: number) => (val !== null ? parsers.decimalFormatter(val, '% p.a.') : 'N/A'),
  avgPerformance5Year: (obj: Object, val: number) => (val !== null ? parsers.decimalFormatter(val, '% p.a.') : 'N/A'),
  avgPerformance10Year: (obj: Object, val: number) => (val !== null ? parsers.decimalFormatter(val, '% p.a.') : 'N/A'),
  avgExpensesRatio: (obj: Object, val: number) => (val !== null ? parsers.decimalFormatter(val, '%') : 'N/A'),
  avgStandardDeviation: (obj: Object, val: number) => (val !== null ? parsers.decimalFormatter(val) : 'N/A'),
  avgCurrentPerformance: (obj: Object, val: number) => (val !== null ? parsers.decimalFormatter(val, '% p.a.') : 'N/A'),
  avgSrri: (obj: Object, val: number) => (val !== null ? parsers.decimalFormatter(val) : 'N/A'),
};

export const millisecondsToDateFormated = (ms: number, stringFormat: string): string =>
  format(new Date(ms), stringFormat);

export const fromYearsToDateRangeStrings = (yearsToSubtract: number) => {
  const toDate = new Date();
  const fromDate = subYears(toDate, yearsToSubtract);
  return {
    to: format(toDate, 'd.M.yyyy'),
    from: format(fromDate, 'd.M.yyyy'),
  };
};

export const funcsPipe = (fns: any) => {
  return fns.reduce(
    (f: any, g: any) =>
      (...args: any[]) =>
        g(f(...args)),
  );
};

export const paramsToUrlMapper = (url: string, params: any): string =>
  Object.keys(params).reduce((res, key, index) => res && res.replace(new RegExp(`:${key}`, 'g'), params[key]), url);

export const objectToQueryString = (obj: Object, prefix?: string): string =>
  [prefix, qs.stringify(obj)].filter(Boolean).join('?');

export const escapeRegExp = (text: string) => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

export const printOnlyNumber = (value: any, valueSymbol: Function | string): string => {
  if (typeof value === 'number') {
    return typeof valueSymbol === 'function' ? valueSymbol(value) : `${value} ${valueSymbol}`;
  }
  return '';
};

/**
 * Returns fontAwsome icon name depending on contentType
 * TODO: add all potentional content types
 * https://technet.microsoft.com/en-us/library/ee309278(office.12).aspx
 */
export const iconByContentType = (contentType: string): string => {
  switch (contentType) {
    case 'application/pdf':
      return 'fa fa-file-pdf-o';
    case 'application/doc':
    case 'application/docs':
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
    case 'application/vnd.ms-word.document.macroEnabled.12':
    case 'application/vnd.ms-word.template.macroEnabled.12':
      return 'fa fa-file-word-o';
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
    case 'application/vnd.ms-excel.sheet.macroEnabled.12':
    case 'application/vnd.ms-excel.template.macroEnabled.12':
    case 'application/vnd.ms-excel.addin.macroEnabled.12':
    case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
      return 'fa fa-file-excel-o';
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.openxmlformats-officedocument.presentationml.template':
    case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
    case 'application/vnd.ms-powerpoint.addin.macroEnabled.12':
    case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
    case 'application/vnd.ms-powerpoint.template.macroEnabled.12':
    case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12':
      return 'fa fa-file-powerpoint-o';
    case 'image/jpeg':
    case 'image/png':
      return 'fa fa-file-image-o';
    default:
      return 'fa fa-paperclip';
  }
};

export const bytesToSize = (bytes: number): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  if (i === 0) return `${bytes} ${sizes[i]})`;

  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

// https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
const b64toBlob = (b64Data: string, contentType: AttachmentContentType, sliceSize?: number) => {
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const downloadFile = (file: Attachment) => {
  const { content, name, contentType } = file;
  const blob = b64toBlob(content, contentType);

  if ((window.navigator as any).msSaveOrOpenBlob) {
    (window.navigator as any).msSaveOrOpenBlob(blob, name);
  } else {
    const a = document.createElement('a');
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = name;
    a.click();

    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 0);
  }
};

export const sortByTableToQuery = (query: SortByTableProps): SortByQuery => ({
  sortBy: {
    ...(query.sortBy ? { [query.sortBy]: query.sortOrder } : {}),
  },
});

export const pluralize = (count: number, str1: string, str2: string, str0: string): string => {
  switch (count) {
    case 1:
      return str1;
    case 2:
    case 3:
    case 4:
      return str2;
    default:
      return str0;
  }
};

export const getInvestmentMinValue = (investment: Investment) => {
  let minValue = 0;
  let key = investment.type === 'REGULAR' ? 'minRegular' : 'minSingle';
  if (
    investment.operation !== 'SALE' && // BTIP-606
    investment.contractAcronym &&
    investment.currency &&
    investment.product &&
    investment.product.templates &&
    investment.product.templates[investment.contractAcronym] &&
    investment.product.templates[investment.contractAcronym][key][investment.currency]
  ) {
    minValue = investment.product.templates[investment.contractAcronym][key][investment.currency];
    return minValue != null ? minValue : undefined;
  }
  return undefined;
};

export const memoizedGetInvestmentMinValue = memoize(getInvestmentMinValue, (investment) =>
  JSON.stringify({
    code: (investment.product && investment.product.code && investment.product.code.code) || undefined,
    type: investment.type,
    contractAcronym: investment.contractAcronym,
    currency: investment.currency,
  }),
);

export const getInvestmentMaxFee = async (investment: Investment) => {
  if (
    investment.operation !== 'SALE' &&
    investment.contractAcronym &&
    investment.currency &&
    investment.value &&
    investment.product &&
    (investment.type !== 'REGULAR' || investment.length) &&
    (investment.type !== 'REGULAR' || investment.feeType)
  ) {
    try {
      const res = await handleRequest(api.INVESTMENTS_MAX_FEE, {
        method: 'POST',
        body: JSON.stringify(investment),
      });
      return parseFloat(res.result);
    } catch (error) {
      // return '- nepodařilo se zjistit minimální poplatek';
      // rather leave it for the backend validation
      console.log("nepodařilo se zjistit minimální poplatek'");
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const memoizedGetInvestmentMaxFee = memoize(getInvestmentMaxFee, (investment) =>
  JSON.stringify({
    contractAcronym: investment.contractAcronym,
    currency: investment.currency,
    feeType: investment.feeType,
    frequency: investment.frequency,
    productEntityId: investment.product && investment.product.entityId,
    value: investment.value,
    type: investment.type,
    length: investment.length,
  }),
);

export const isOver18FromDOB = (dob: string) => {
  // eslint-disable-next-line
  const regex = /^([0-9]{1,2})[\.][\s]?([0-9]{1,2})[\.][\s]?([0-9]{4})$/;
  const match = regex.exec(dob);
  if (match) {
    const day = parseInt(match[1], 10);
    const month = parseInt(match[2], 10);
    const year = parseInt(match[3], 10);
    const now = new Date();
    return (
      now.getFullYear() - year >= 18 &&
      (now.getMonth() + 1 > month || (now.getMonth() + 1 === month && now.getDate() >= day))
    );
  } else {
    return false;
  }
};

export const isOver18FromPersonalNumber = (personalNumber?: string) => {
  if (personalNumber && personalNumber.length > 5) {
    const now = new Date();
    const year = parseInt(personalNumber.substr(0, 2), 10);
    const month = parseInt(personalNumber.substr(2, 2), 10);
    const day = parseInt(personalNumber.substr(4, 2), 10);
    const dobYear = year + (year + 2000 > now.getFullYear() ? 1900 : 2000);
    const dobMonth = month - (month > 50 ? 50 : 0);

    return (
      now.getFullYear() - dobYear >= 18 &&
      (now.getMonth() + 1 > dobMonth || (now.getMonth() + 1 === dobMonth && now.getDate() >= day))
    );
  }
  return undefined;
};

export const groupTransferInvestments = <T extends { operation: OperationEnum }>(
  investments: T[],
  transferInstrumentId?: string,
) => {
  const groupedInvestments = groupBy(investments, 'transferInstrumentId');

  // Nothing to group - not transfer investments
  if (groupedInvestments['undefined'] || groupedInvestments['null']) {
    return groupedInvestments;
  }

  // filter by a specific transfer id
  if (typeof transferInstrumentId === 'string') {
    const transfers = groupedInvestments[transferInstrumentId];
    return transfers ? getTransferInvestments(transfers) : groupedInvestments;
  } else {
    return groupedInvestments;
  }
};

// Not sure if generic is needed here, maybe there can be exact type
export const getTransferInvestments = <T extends { operation: OperationEnum }>(
  investments: T[],
): { transferSourceInvest?: T; transferTargetInvestments?: T[] } => {
  if (!investments) return {};

  const transferSourceInvest = investments.find((item) => item.operation === OperationEnum.TRANSFER_SALE);
  const transferTargetInvestments = investments.filter((item) => item.operation === OperationEnum.TRANSFER_PURCHASE);

  return {
    transferSourceInvest,
    transferTargetInvestments,
  };
};

/**
 * Takes an object of touched form values and removes all false ones. If `isObjEmpty` is `true`, returns `true/false` info instead of an object
 * @param {Object} data form touched values
 * @param {Boolean} isObjEmpty wheter return a true/false or a cleansed object
 * @returns Object | Boolean
 */
export const formTouchedValues = (data: any, isObjEmpty?: boolean): Object | boolean => {
  const cleansedObject = omitBy(data, (item) => item === false);

  return isObjEmpty ? !isEmpty(cleansedObject) : cleansedObject;
};

// TODO: udelat z funkce hook
export const getValueSuffix = (values: any): string | null => {
  if (!values) return null;

  if (values.sellAll) {
    return '%';
  } else if (values.usingShares) {
    console.log(values.usingShares);
    return 'ks';
  } else {
    return values.currency;
  }
};

export function focusFirstInvalidInput(handleSubmit: FormRenderProps['handleSubmit']) {
  return async (evt: React.SyntheticEvent<HTMLFormElement>) => {
    const formElem = evt.target as HTMLFormElement;
    await handleSubmit(evt);

    const invalidFormItem = (formElem || document).querySelector('.FormItem--invalid');
    if (invalidFormItem) {
      const prevScrollBehavior = document.documentElement.style.scrollBehavior;
      document.documentElement.style.scrollBehavior = 'smooth';

      const invalidInput = invalidFormItem.querySelector('input, select') as HTMLElement;
      if (invalidInput) {
        invalidInput.focus();
      } else {
        invalidFormItem.scrollIntoView();
      }

      document.documentElement.style.scrollBehavior = prevScrollBehavior;
    }
  };
}
